import * as React from "react"
import Layout from "../components/layout.js"
import Image from "../components/image"

const ForestSpiritTypeIIPage = () => {
  return (
    <Layout title="Forest Spirit, Type II">
        <h2>Field Notes</h2>
        <center><Image src="forestspirit-typeii-1.png" alt="Drawing" /></center>
        <p>I have classified this being as a type of FOREST SPIRIT. It can be found in any forest throughout the world, though sightings are rare.</p>
        <p>This is an intelligent creature able to interact in a limited fashion with humans, should one wish to take the risk.</p>
        <p>&nbsp;</p>
        <center><Image src="forestspirit-typeii-2.png" alt="Drawing" /></center>
        <ul>
            <li>Great variety in facial structure, from appealing to grotesque. Older specimens such as this one tend to be more attractive to the human eye.</li>
            <li>Skintone tends to mimic the bark colors of local trees.</li>
        </ul>
        <p>&nbsp;</p>
        <center><Image src="forestspirit-typeii-3.png" alt="Drawing" /></center>
        <ul>
            <li>Rigid, hard tissue grows in branch-like spines from the top of the head. As it ages, the tissue also begins to grow down the body.</li>
            <li>Eyelids are mushroom-like, while pupils are slitted.</li>
            <li>Nose is slit-like.</li>
            <li>Teeth are jagged, sharp, and will regrow if lost.</li>
            <li>Carnivorous. Very aggressive despite sometimes nymph-like features. Will bite without provocation!</li>
            <li>This variety of forest spirit lives in the mouths of caves. It has an indeterminate growth. Specimens up to 2.5m have been observed.</li>
        </ul>
        <p>&nbsp;</p>
        <center><Image src="forestspirit-typeii-4.png" alt="Drawing" /></center>
        <center>foot placeholder</center>
        <center>eyes placeholder</center>
        <ul>
            <li>The hands have six digits: five fingers and an opposable thumb. The digits are long, spiny, and often crooked and conjoined. Despite the fragile appearance, the hands are quite strong.</li>
            <li>Wrists and hands have a veiny appearance.</li>
        </ul>
        <p>&nbsp;</p>
        <center>crown placeholder</center>
        <ul>
            <li>The crown or crest is its most notable feature. As a juvenile, the crown is a series of stubs or small spines. As it ages, the spines grow longer, often branching off.</li>
            <li>With age, the crown also begins to grow downward along the face, throat, and body, resembling roots.</li>
        </ul>
        <p>&nbsp;</p>
        <center>environment placeholder</center>
        <ul>
            <li>It lives in the mouths of caves in forested regions.</li>
            <li>Its diet primarily consists of birds, insects, and rodents. However, larger specimens will pursue larger prey including deer and elk, wolves, foxes, and even humans.</li>
            <li>I observed no male specimens during my explorations. The residents of the village where I stayed have informed me that no one has ever seen a male specimen, nor been able to determine how this species reproduces.</li>
        </ul>
    </Layout>
  )
}

export default ForestSpiritTypeIIPage
